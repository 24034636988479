'use client';

import { Heading, Text, VStack } from '@chakra-ui/react';

import { Img, PageContainer, PageContent } from 'components';
import { useLayout } from 'components/Layout/useLayout';

import SearchImg from 'assets/search.svg';

export const NotFoundPage = async () => {
  const { layoutContent } = useLayout();

  const { error: errorContent } = layoutContent || {};

  const { genericMessage = '', messagesByStatusCode = [] } = errorContent || {};

  const messageByStatus = messagesByStatusCode.find(
    ({ statusCode }) => statusCode === '404',
  );

  const message = messageByStatus?.message || genericMessage;

  return (
    <PageContainer isFullHeight py={0}>
      <PageContent align="center" justify="center" py={0}>
        <VStack maxW="37.5rem" spacing="2.5rem">
          <Heading fontSize="8rem">404</Heading>

          <Img src={SearchImg} alt="Search" />

          <Text textAlign="center">{message}</Text>
        </VStack>
      </PageContent>
    </PageContainer>
  );
};

export default NotFoundPage;
